import React from "react"
import styles from "./blockLeftIntro.module.css"

const BlockLeftIntro = ({ bgColor, data }) => {
  const {
    title,
    text,
    img: {
      uri: { url: bgImg },
    },
  } = data
  return (
    <section
      className={styles.blockLeftIntro}
      style={{
        backgroundImage: `url(https://drupal.smartempo.com${bgImg})`,
      }}
    >
      <div className={styles.blockLeftIntro__text}>
        <p>{text}</p>
      </div>
      <div className={styles.blockLeftIntro__title}>
        <p>{title}</p>
      </div>
    </section>
  )
}

export default BlockLeftIntro
