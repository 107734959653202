import React from "react"
import styles from "./blockLeftTextOverlap.module.css"

const BlockLeftTextOverlap = ({ bgColor, data }) => {
  const {
    field_left_text_overlap_title_: title,
    field_left_text_overlap_text_: text,
  } = data
  return (
    <section className={styles.blockLeftTextOverlap}>
      <div className={styles.blockLeftTextOverlap__text}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </section>
  )
}
export default BlockLeftTextOverlap
