import React from "react"
import styles from "./blockTwoBlocks.module.css"

const BlockTwoBlocks = ({ bgColor, data }) => {
  const {
    field_two_blocks_1_title_: title1,
    field_two_blocks_1_text_: text1,
    field_two_blocks_2_title_: title2,
    field_two_blocks_2_text_: text2,
  } = data
  return (
    <section className={styles.blockTwoBlocks}>
      <div className={styles.blockTwoBlocks__blockUp}>
        <h3>{title1}</h3>
        <p>{text1}</p>
      </div>
      <div className={styles.blockTwoBlocks__block}>
        <h3>{title2}</h3>
        <p>{text2}</p>
      </div>
    </section>
  )
}

export default BlockTwoBlocks
