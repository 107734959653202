import React from "react"
import styles from "./blockTextImgHalfHeight.module.css"
const BlockTextImgHalfHeight = ({ bgColor, data }) => {
  const {
    title,
    text,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockTextImgHalfHeight}>
      <div className={styles.blockTextImgHalfHeight__text}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <div className={styles.blockTextImgHalfHeight__img}>
        <img src={`https://drupal.smartempo.com/${image}`} alt={title} />
      </div>
    </section>
  )
}
export default BlockTextImgHalfHeight
