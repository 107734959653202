import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockLeftIntro from "../components/BlockLeftIntro/BlockLeftIntro"
import BlockImageMargin from "../components/BlockImageMargin/BlockImageMargin"
import BlockTwoBlocks from "../components/BlockTwoBlocks/BlockTwoBlocks"
import BlockLeftTextOverlap from "../components/BlockLeftTextOverlap/BlockLeftTextOverlap"
import BlockTitleTextOverlap from "../components/BlockTitleTextOverlap/BlockTitleTextOverlap"
import BlockTextImgHalfHeight from "../components/BlockTextImgHalfHeight/BlockTextImgHalfHeight"
import BlockTextImgUp from "../components/BlockTextImgUp/BlockTextImgUp"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockTopImg from "../components/BlockTopImg/BlockTopImg"
import BlockTextCenter from "../components/BlockTextCenter/BlockTextCenter"
import BlockFullWidthImg from "../components/BlockFullWidthImg/BlockFullWidthImg"
import BlockBandTextImage from "../components/BlockBandTextImage/BlockBandTextImage"
import Footer from "../components/Footer/Footer"

import { useMartechPage } from "../hooks/use-martechPage"

const MarketingTechnology = () => {
  const martechData = useMartechPage()
  const blockLeftIntroData = {
    title: martechData.field_left_intro_title_,
    text: martechData.field_left_intro_text_,
    img: martechData.relationships.field_left_intro_img_,
  }
  const fullWidthImage1Data = {
    img: martechData.relationships.field_full_width_img,
  }
  const fullWidthImage2Data = {
    img: martechData.relationships.field_full_width_img_2,
  }
  const titleTextOverlapData = {
    title: martechData.field_title_text_overlap_title_,
    text: martechData.field_title_text_overlap_text_,
    img: martechData.relationships.field_title_text_overlap_img_,
  }
  const textImgHalfHeight = {
    title: martechData.field_textimghalfheight_title_,
    text: martechData.field_textimghalfheight_text_,
    img: martechData.relationships.field_textimghalfheight_img_,
  }
  const textImgUp = {
    title: martechData.field_text_image_up_title_,
    text: martechData.field_text_image_up_text_,
    img: martechData.relationships.field_text_image_up_img_,
  }
  const twoColumnsData = {
    titleOne: "",
    textOne: martechData.field_two_columns_col_1_text_,
    titleTwo: "",
    textTwo: martechData.field_two_columns_col_2_text_,
  }
  const topImg = {
    img: martechData.relationships.field_top_img,
  }
  const bandTextImageData = {
    text: martechData.field_band_text_image_text_,
    img: martechData.relationships.field_band_text_image_img_,
  }
  // const textCenterData = {
  //   title: martechData.field_text_center_title_,
  //   text: martechData.field_text_center_text_.processed,
  // }
  console.log(martechData)

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockLeftIntro data={blockLeftIntroData} />
        <BlockImageMargin data={martechData} />
        <BlockTwoBlocks data={martechData} />
        <BlockFullWidthImg img={fullWidthImage1Data} />
        <BlockLeftTextOverlap data={martechData} />
        <BlockTitleTextOverlap data={titleTextOverlapData} />
        <BlockTextImgHalfHeight data={textImgHalfHeight} />
        <BlockTextImgUp data={textImgUp} />
        <BlockTwoColumns
          bgColor="bg-cyan"
          fontColor="font-white"
          data={twoColumnsData}
        />
        <BlockTopImg img={topImg} />
        {/* <BlockTextCenter titleColor="font-cyan" data={textCenterData} /> */}
        <BlockFullWidthImg img={fullWidthImage2Data} />
        {/* <BlockBandTextImage data={bandTextImageData} /> */}
        <Footer />
      </div>
    </div>
  )
}

export default MarketingTechnology
