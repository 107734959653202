import React from "react"
import styles from "./blockTopImg.module.css"
const BlockTopImg = ({ bgColor, img }) => {
  // const {
  //   img: {
  //     uri: { url: image },
  //   },
  // } = img
  return (
    <section className={styles.blockTopImg}>
      <div className={styles.blockTopImg__img}>
        {/* <img src={`https://drupal.smartempo.com/${image}`} alt="Image" /> */}
      </div>
    </section>
  )
}
export default BlockTopImg
