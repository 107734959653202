import { useStaticQuery, graphql } from "gatsby"

export const useMartechPage = () => {
  const { allNodeBlueServices } = useStaticQuery(
    graphql`
      query MarketingTechnologyPage {
        allNodeBlueServices {
          edges {
            node {
              field_band_text_image_text_
              field_title_text_overlap_title_
              field_title_text_overlap_text_
              field_left_intro_text_
              field_left_intro_title_
              field_left_text_overlap_text_
              field_left_text_overlap_title_

              field_text_image_up_text_
              field_text_image_up_title_
              field_textimghalfheight_text_
              field_textimghalfheight_title_
              field_two_blocks_1_text_
              field_two_blocks_1_title_
              field_two_blocks_2_text_
              field_two_blocks_2_title_
              field_two_columns_col_1_title_
              field_two_columns_col_2_title_
              field_two_columns_col_1_text_ {
                processed
              }
              field_two_columns_col_2_text_ {
                processed
              }
              relationships {
                field_band_text_image_img_ {
                  uri {
                    url
                  }
                }
                field_left_intro_img_ {
                  uri {
                    url
                  }
                }
                field_full_width_img {
                  uri {
                    url
                  }
                }
                field_full_width_img_2 {
                  uri {
                    url
                  }
                }
                field_imagemargin_img_ {
                  uri {
                    url
                  }
                }
                field_text_image_up_img_ {
                  uri {
                    url
                  }
                }
                field_textimghalfheight_img_ {
                  uri {
                    url
                  }
                }
                field_title_text_overlap_img_ {
                  uri {
                    url
                  }
                }
                field_top_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeBlueServices.edges[0].node
}

// field_text_center_text_ {
//   processed
// }
// field_text_center_title_
